@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import "common/styles";
@import "common/styles/vars";
@import "common/styles/functions";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  background: v(appBackground, #f9f9f9);
  position: relative;
  text-align: left;
  width: 100%;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: v(appText, #333);
  padding: 0;
  margin: 0;
}

.center {
  text-align: center;
  margin: 0 auto;
}